<template>
  <!-- 预约管理 -->
  <div class="page1">
    <div class="content">
      <!-- 搜索条件区域 -->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="停车场名称:">
                <el-autocomplete
                  v-model="state"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  @select="handleSelect"
                  value-key="parkName"
                  :trigger-on-focus="false"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="商品状态:">
                <el-select v-model.trim="formInline.proStatus" filterable>
                  <el-option label="全部状态" value></el-option>
                  <el-option label="出售中" value="1"></el-option>
                  <el-option label="已下架" value="2"></el-option>
                  <el-option label="未上架" value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="创建人:">
                <!-- <el-input v-model="formInline.createMen" placeholder="请输入名称"></el-input> -->

                <el-autocomplete
                  v-model="bossManagerName"
                  :fetch-suggestions="querySearchAsyncCreator"
                  placeholder="请输入内容"
                  @select="handleSelectCreator"
                  value-key="bossManagerName"
                  :trigger-on-focus="false"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="预约开放日期：">
                <el-date-picker
                  v-model="searchDateS"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
              <el-form-item label="预约结束日期：">
                <el-date-picker
                  v-model="searchDateE"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                @click="searchData(1)"
                v-if="$route.meta.authority.button.query"
                icon="el-icon-search"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                @click="addProduct"
                icon="el-icon-plus"
                v-if="$route.meta.authority.button.add"
                >{{ $t('button.addto') }}</el-button
              >
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          border
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <!-- <el-table-column type="index" :label="$t('list.index')" :index="indexMethod" width="70" align="center"></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="80">
            <template slot-scope="scope">
              <AuthorityComponent
                ComponentName="el-dropdown"
                align="center"
                @command="handleCommand($event, scope.row)"
                width="80"
              >
                <!-- <el-button type="text" size="small" style="padding: 0">操作<i class="el-icon-arrow-down" /></el-button> -->
                <span class="el-dropdown-link">
                  操作
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1" v-if="$route.meta.authority.button.view"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="2"
                    v-if="$route.meta.authority.button.edit && scope.row.goodState == 0"
                    >修改</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="3"
                    v-if="$route.meta.authority.button.up && scope.row.goodState == 0"
                    >上架</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="4"
                    v-if="$route.meta.authority.button.down && scope.row.goodState == 1"
                    >下架</el-dropdown-item
                  >
                </el-dropdown-menu>
              </AuthorityComponent>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-show="total > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/common/js/public.js";
export default {
  name: "reserveParking",
  data() {
    return {
      state: "",
      bossManagerName: "",
      total: 0,
      pageSize: 15,
      page: 1,
      index: 0,
      loading: false,
      searchDateS: "",
      searchDateE: "",
      formInline: {
        proStatus: "",
        parkId: "",
        bossManagerNameId: "",
      },
      tableData: [],
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "165",
        },
        {
          prop: "appointmentBerthNumber",
          label: "预约泊位数量",
          formatter: (row) => {
            if (row.appointmentBerthNumber == -1) {
              return "不限";
            } else {
              return row.appointmentBerthNumber;
            }
          },
        },
        {
          prop: "appointmentStartDate",
          label: "预约开放日期",
        },
        {
          prop: "appointmentEndDate",
          label: "预约结束日期",
        },
        {
          prop: "depositMoneyStr",
          label: "预约押金",
          formatter: (row) => {
            if (row.depositMoney == -1) {
              return "免押金";
            } else {
              return row.depositMoneyStr;
            }
          },
        },
        {
          prop: "goodState",
          label: "商品状态",
          formatter: (row) => {
            const result = ["未上架", "出售中", "已下架", ""];
            return result[row.goodState];
          },
        },
        {
          prop: "creatorName",
          label: "创建人",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_time"),
          width: "165",
        },
      ],
    };
  },
  created() {
    this.searchData();
    // console.log(this.$store.getters['tabs/cacheTabs'])
  },
  watch: {
    state(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
      }
    },
    bossManagerName(newVal) {
      if (!newVal) {
        this.formInline.bossManagerNameId = "";
      }
    },
  },
  methods: {
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/systems/loginUser/parkNameList", {
          data: {
            parkTypes: "3,4",
            slaveRelations: "0,2",
            parkName: this.state,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline.parkId = "";
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    handleSelect(item) {
      this.formInline.parkId = item.parkId;
      // console.log(item);
    },
    querySearchAsyncCreator(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/bossManager/list", {
          data: {
            page: 1,
            size: 20,
            bossManagerName: this.bossManagerName,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline.bossManagerNameId = "";
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    handleSelectCreator(item) {
      this.formInline.bossManagerNameId = item.bossManagerId;
      // console.log(item);
    },
    searchData(tag) {
      if (tag == 1) {
        this.page = 1;
      }

      this.tableData = [{ name: "李扬" }];

      // console.log(this.searchDateS);
      // console.log(this.searchDateE);

      this.$axios
        .get("/acb/2.0/parkAppointmentGood/getByPage", {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            parkId: this.formInline.parkId,
            goodState: this.formInline.proStatus,
            appointmentStartDateBegin:
              this.searchDateS == null || this.searchDateS == ""
                ? ""
                : dateFormat(this.searchDateS[0], "yyyy-MM-dd"),
            appointmentStartDateEnd:
              this.searchDateS == null || this.searchDateS == ""
                ? ""
                : dateFormat(this.searchDateS[1], "yyyy-MM-dd"),
            appointmentEndDateBegin:
              this.searchDateE == null || this.searchDateE == ""
                ? ""
                : dateFormat(this.searchDateE[0], "yyyy-MM-dd"),
            appointmentEndDateEnd:
              this.searchDateE == null || this.searchDateE == ""
                ? ""
                : dateFormat(this.searchDateE[1], "yyyy-MM-dd"),
            creatorId: this.formInline.bossManagerNameId,
          },
        })
        .then(
          (res) => {
            if (res.state == 0) {
              this.tableData = res.value.list;
              this.loading = false;
              this.total = res.value.total * 1;
            } else {
              // console.log("-->asdas", res);
              // this.$alert(res.desc, this.$t('pop_up.Tips'), {
              //   confirmButtonText: this.$t('pop_up.Determine')
              // });
            }
          },
          (rej) => {
            // 测试的时候加入
            // console.log("-->rej-->", rej);
            // this.tableData = rej.list;
            // this.loading = false;
            // this.total = rej.total * 1;
          }
        );
    },
    indexMethod(index) {
      return index + 1;
    },
    handleCommand(cmd, data) {
      if (cmd == "1") {
        // 查看
        this.$router.push({
          path: "/seeBKManageDetails",
          query: data,
        });
      }
      if (cmd == "2") {
        // 修改
        // console.log("data--》", data);
        this.$router.push({
          path: "/addBookingManage",
          query: data,
        });
      }
      if (cmd == "3") {
        // 上架

        this.$confirm("确定上架此商品吗?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$axios
              .post("/acb/2.0/parkAppointmentGood/putOrOffShelf", {
                data: {
                  appointmentGoodId: data.appointmentGoodId,
                  goodState: 1,
                },
              })
              .then((res) => {
                // console.log("==->res", res);
                if (res.state == 0) {
                  this.$message({
                    type: "success",
                    message: "上架成功!",
                  });
                }
                this.searchData();
              });
          })
          .catch(() => {});
      }
      if (cmd == "4") {
        // 下架
        this.$confirm("确定下架此商品吗?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$axios
              .post("/acb/2.0/parkAppointmentGood/putOrOffShelf", {
                data: {
                  appointmentGoodId: data.appointmentGoodId,
                  goodState: 2,
                },
              })
              .then((res) => {
                // console.log("==->res", res);
                if (res.state == 0) {
                  this.$message({
                    type: "success",
                    message: "下架成功!",
                  });
                }
                this.searchData();
              });
          })
          .catch(() => {});
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    addProduct() {
      this.$router.push("/addBookingManage");
    },
  },
  components: {},
  mounted() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.el-dropdown-link {
  cursor: pointer;
  color: #0f6eff;
}
</style>
<style scoped></style>
